import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-shop",
  templateUrl: "./shop.component.html",
  styleUrls: ["./shop.component.scss"],
})
export class ShopComponent implements OnInit {
  public themeLogo: string = "assets/images/icon/logo-wh-trans.png";
  public themeLogoFooter: string = "assets/images/icon/logo-blk-trans.png";

  constructor() {}

  ngOnInit(): void {}
}
