<app-header-two
  [sticky]="true"
  [topbar]="false"
  [themeLogo]="themeLogoHdr"
  *ngIf="url != '/pages/comingsoon'"
></app-header-two>
<router-outlet></router-outlet>
<app-footer-two
  [themeLogo]="themeLogo"
  *ngIf="url != '/pages/comingsoon'"
></app-footer-two>
